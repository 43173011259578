<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">New Orientation Batch</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-8">
                  <div class="row mb-2">
                    <label for="batch" class="col-md-3 form-label">
                      Orientation Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        id="batch"
                        name="batch"
                        class="form-control"
                        placeholder="Orientation Batch"
                        v-model="upcoming_batch"
                        style="cursor: not-allowed"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="short_name" class="col-md-3 form-label">
                      Program Batch
                    </label>
                    <div class="col-md-7">
                      <!-- @option:selected="getSelectedBatch" -->
                      <v-select
                        multiple
                        v-model="selectedBatch"
                        label="batch_no"
                        :options="batches"
                        :clearable="false"
                        :selectable="(options) => isSelectable(options)"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                        :disabled="alreadyCreatedBatch"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-5"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div v-if="!loading" class="row mt-2 text-center">
                <div class="d-flex">
                  <div class="col-md-1">
                    <label for="name" class="form-label"> No </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label"> Employee Name </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label"> Employee ID </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label"> Program Name </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label"> Batch </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label"> Join Date </label>
                  </div>
                </div>
              </div>

              <div class="row mb-4" v-if="employeeLists">
                <div
                  v-for="(item, index) in batch.program_batches"
                  :key="index"
                  class="d-flex m-2"
                >
                  <div class="col-md-1 ms-2">
                    <span>{{ ++index }}</span>
                  </div>
                  <div class="col-md-2 me-3">
                    <span>{{ item.employee_name }}</span>
                  </div>
                  <div class="col-md-2">
                    <span>{{ item.employee_id }}</span>
                  </div>
                  <div class="col-md-2">
                    <span>{{ item.program_name }}</span>
                  </div>
                  <div class="col-md-2">
                    <span>{{ item.batch }}</span>
                  </div>
                  <div class="col-md-2 me-2">
                    <span>{{ item.join_date }}</span>
                  </div>
                </div>
              </div>
              <!-- end row -->
              <div class="row" v-show="!alreadyCreatedBatch">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'orientation-batch' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading || batch.program_batches.length == 0"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'orientation-batch-update'
                          ? updateOrientationBatch()
                          : createOrientationBatch()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors],
  data() {
    return {
      alreadyCreatedBatch: false,
      upcoming_batch: "",
      batches: [],
      selectedBatch: null,
      employeeLists: [],
      oldBatchDatas: [],
      batch: {
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        available: "",
        program_batches: [
          {
            employee_name: "",
            employee_id: "",
            program_name: "",
            batch: "",
            join_date: "",
          },
        ],
      },
      created_batches: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      selectedBatch: { required },
    };
  },
  watch: {
    selectedBatch(value) {
      this.changeSelectedValue(value);
    },
  },
  methods: {
    isSelectable(options) {
      const isUsed = this.created_batches.some(
        (batch) => batch?.program_batch[0]?.id === options.id
      );
      if (this.selectedBatch) {
        const isSelected = this.selectedBatch.some(
          (batch) => batch.id === options.id
        );
        return !(isSelected || isUsed);
      }
      return !isUsed;
    },

    changeSelectedValue(value) {
      if (value) {
        value.map((item) => {
          this.batch.program_batch_id = item.id;
          this.batch.program_batch_no = item.batch_no;
          this.batch.join_date = item.join_date;
          this.batch.available = item.available;
          this.getEmployeeByBatch(this.batch.program_batch_id);
        });
      }
      if (value.length == 0) {
        this.batch.program_batches = [];
      }
    },

    async getUpcomingOrientationBatch() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/upcoming-orientation-batch`)
        .then((response) => {
          this.upcoming_batch = response.data.data.upcoming_batch_no;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },

    async getBatch() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/program-batches/list`)
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },

    // getSelectedBatch() {
    //     if (this.selectedBatch) {
    //         this.selectedBatch.map((item) => {
    //             this.batch.program_batch_id = item.id;
    //             this.batch.program_batch_no = item.batch_no;
    //             this.batch.join_date = item.join_date;
    //             this.batch.available = item.available;
    //             this.getEmployeeByBatch(this.batch.program_batch_id);
    //         })
    //     }
    // },

    async getEmployeeByBatch(batch_id) {
      if (this.alreadyCreatedBatch) {
        return;
      }
      this.loading = true;
      this.employeeLists = [];
      var data = "";
      this.batch.program_batches = [];

      await axios
        .get(`${this.baseUrl}admin/v1/program-batch/employees/list/${batch_id}`)
        .then((response) => {
          this.employeeLists = response.data.data;

          this.employeeLists.employee_ids.map((item) => {
            data = {
              employee_name: item.employee.name,
              employee_id: item.employee.emp_id,
              program_name: this.employeeLists.program.name,
              batch: this.employeeLists.batch_no,
              join_date: item.join_date,
              program_id: this.employeeLists.program.id,
              batch_id: batch_id,
            };
            this.batch.program_batches.push(data);
          });

          // this.batch.program_batches = this.employeeLists.employee_ids.map((item) => {
          //     return {
          //         employee_name: item.name,
          //         employee_id: item.employee_ID,
          //         program_name: this.employeeLists.program_id.name.name,
          //         batch: this.employeeLists.batch_no,
          //         join_date: item.join_date,
          //     }
          // });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.toast.error("Not Found Employee!");
        });
    },

    async getOrientationBatchById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/orientation-batch/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
          console.log(result,'resul')
          this.upcoming_batch = result.batch.slice(4);
          this.selectedBatch = result.program_batch;
          if (result.orientation_award) {
            this.employeeLists = result.behavior_assessment_details;

            this.employeeLists = this.employeeLists.map((item) => {
              return {
                employee_name: item.employee.name,
                employee_id: item.employee.emp_id,
                program_name: item.program.name,
                batch: item.batch,
                join_date: this.selectedBatch[0].join_date,
              };
            });
            this.batch.program_batches = this.employeeLists;
            this.alreadyCreatedBatch = true;
          }
        })
        .catch(() => {
          this.toast.error("Not Found Data!");
        });
      this.loading = false;
    },

    async getOldScheduleDatas() {
      this.batch.program_batches = this.oldBatchDatas;
    },

    async createOrientationBatch() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      await axios
        .post(`${this.baseUrl}admin/v2/orientation-batch`, {
          program_batches: this.selectedBatch,
          batch_emp: this.batch.program_batches,
        })
        .then(() => {
          this.$router.push({ name: "orientation-batch" });
          this.toast.success("Successfully Created OrientationBatch!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateOrientationBatch() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v2/orientation-batch/${this.$route.params.id}`,
          {
            program_batches: this.selectedBatch,
            batch_emp: this.batch.program_batches,
          }
        )
        .then(() => {
          this.$router.push({ name: "orientation-batch" });
          this.toast.success("Successfully OrientationBatch Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
    async getAllOrientationBatch() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v2/orientation-batch`)
        .then((response) => {
          this.created_batches = response.data.data;
        })
        .catch((error) => {
          this.errors = error?.response?.data?.errors;
          this.toast.error("Something Went Wrong!");
        });
    },
  },
  created() {
    this.getAllOrientationBatch();
    this.getBatch();
    this.getOldScheduleDatas();
    if (this.currentRouteName == "orientation-batch-create") {
      this.getUpcomingOrientationBatch();
    }
    if (this.currentRouteName == "orientation-batch-update") {
      this.getOrientationBatchById();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
